<template>
  <div class="uk-container full-page">
    <UserHeader />
    <div class="uk-margin-bottom">
      <h1>Test Items</h1>
      <div class="uk-grid-small uk-child-width-1-4@s uk-margin-bottom" uk-grid>
        <div class="uk-position-relative">
          <input v-model="searchTerm" placeholder="Item Name" class="uk-input uk-margin-bottom-small" />
          <button v-if="searchTerm" @click="clearSearch" class="clear-button">×</button>
        </div>
        
        <div class="uk-position-relative">
          <select v-model="selectedCategory" class="uk-select uk-margin-bottom-small">
            <option value="" disabled selected>Select Category</option>
            <option v-for="category in this.categories" :key="category.ItemCategoryId" :value="category.ItemCategoryId">{{ category.CategoryName }}</option>
          </select>
        </div>
        
        <div class="uk-position-relative">
          <select v-model="selectedGroup" class="uk-select uk-margin-bottom-small">
            <option value="" disabled selected>Select Group</option>
            <option v-for="group in groups" :key="group.ItemGroupId" :value="group.ItemGroupId">{{ group.GroupName }}</option>
          </select>
        </div>

        <div class="uk-position-relative">
          <button @click="fetchItems" class="uk-button uk-button-primary">Search</button>
        </div>
      </div>
      <div v-if="loading" class="loading-spinner">
        <div uk-spinner></div>
      </div>
      <table v-if="!loading && items && items.length">
        <thead>
          <tr>
            <th class="table-width-50">Id</th>
            <th>TimeWaver Name</th>
            <th>Test Result Name</th>
            <th>Human Description</th>
            <th>Pet Description</th>
            <th>Last Updated At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.ItemId">
            <td class="table-width-50">{{ item.ItemId }}</td>
            <td>
              {{ item.ItemName }}
              <div class="uk-margin-top">
                <span class="Category">{{ item.CategoryName }}</span>
                <span class="Group">{{ item.GroupName }}</span>
              </div>
            </td>
            <td>{{ item.ItemNameFriendly }}</td>
            <td>{{ item.ItemDescription }}</td>
            <td>{{ item.ItemDescriptionPet }}</td>
            <td class="nowrap">
              {{ convertUtcToLocal(item.LastModifiedDateUtc) }}
            </td>
            <td class="nowrap">
              <img 
                src="/images/edit.svg" 
                uk-toggle="target: #edit-item-modal"
                uk-tooltip="Edit Item" 
                @click="selectItem(item)" 
                class="action-icon uk-margin-right-small"
                />
            </td>            
          </tr>
        </tbody>
      </table>
      <div v-if="totalPages && totalPages > 1" class="pagination-controls">
        <button class="uk-button uk-button-secondary uk-margin-right" @click="prevPage" :disabled="currentPage === 1">Previous</button>
        <button class="uk-button uk-button-secondary" @click="nextPage" :disabled="currentPage === totalPages">Next</button>
      </div>
      <div v-if="totalPages && totalPages > 1" class="uk-text-small uk-margin-top">
        Page {{ currentPage }} of {{ formattedTotalPages }} ({{formattedTotalItems}} records)
      </div>
    </div>
  </div>
  <EditItemModal v-if="selectedItem" 
    :item="selectedItem"
    @close="closeModal" 
    @item-updated="updateItem" />
</template>

<script>
import axios from 'axios';
import UserHeader from './UserHeader.vue'; 
import EditItemModal from '@/components/EditItemModal';
import { convertUtcToLocal } from '@/utils/common';

export default {
  created() {
    document.title = 'Test Items | Admin | UCARI';
    this.accessToken = localStorage.getItem('adminAccessToken');
    this.cid = localStorage.getItem('adminCid');
  },
  components: {
    UserHeader,
    EditItemModal
  },
  data() {
    return {
      items: [],
      categories: [],
      groups: [],
      selectedGroup: '',
      selectedCategory: '',
      selectedItem: null,
      currentPage: 1,
      totalPages: 0,
      totalItems: 0,
      searchTerm: '',
      isAuthenticated: false,
      cid: '',
      accessToken: '',
      loading: false
    };
  },
  methods: {
    async fetchCategories() {
      this.loading = true;
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/test-items-categories`, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          },
          method: 'GET'
        });
        this.categories = response.data.items;
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        this.loading = false;
      }
    },  
    async fetchGroups() {
      this.loading = true;
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/test-items-groups`, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          },
          method: 'GET'
        });
        this.groups = response.data.items;
      } catch (error) {
        console.error('Error fetching groups:', error);
      } finally {
        this.loading = false;
      }
    },
    async fetchItems() {
      this.loading = true;
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/test-items`, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          },
          method: 'GET',
          params: {
            page: this.currentPage,
            limit: 20,
            search: this.searchTerm,
            group: this.selectedGroup,
            category: this.selectedCategory
          }
        });
        this.items = response.data.items;
        this.currentPage = response.data.currentPage;
        this.totalPages = response.data.totalPages;
        this.totalItems = response.data.totalItems;
      } catch (error) {
        console.error('Error fetching items:', error);
      } finally {
        this.loading = false;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchItems();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchItems();
      }
    },
    clearSearch() {
      this.searchTerm = '';
      this.fetchItems();
    },
    selectItem(item) {
      this.selectedItem = item;
    },
    closeModal() {
      this.selectedItem = null;
    },
    updateItem(updatedItem) {
      // Find the index of the item in the items array
      const index = this.items.findIndex(item => item.ItemId === updatedItem.ItemId);
      if (index !== -1) {
        // Directly update the item in the items array
        this.items[index] = updatedItem;
      }
      // Close the modal after update
      this.closeModal();
    },
    convertUtcToLocal
  },
  computed: {
    formattedTotalPages() {
      return this.totalPages.toLocaleString();
    },
    formattedTotalItems() {
      return this.totalItems.toLocaleString();
    }
  },
  mounted() {
    this.fetchCategories();
    this.fetchGroups();
    this.fetchItems();
  }
};
</script>
